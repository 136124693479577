import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductCell from "../components/Product/ProductCell"
import Search from "../components/Search"

import { retrieveOrders } from "api/db"

const Home = ({ data }) => {
  const [orderInProgress, setOrderInProgress] = useState(false)
  const [activeOrder, setActiveOrder] = useState([])
  const [term, setTerm] = useState("")

  useEffect(() => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const query = params.get("query")
    setTerm(query)
  })

  let allProducts = data.allGoogleSpreadsheetSheet1.nodes

  let uid
  // if logged it retreieve to see if there's an active order!!
  if (typeof window !== "undefined") {
    uid = JSON.parse(localStorage.getItem("uid"))
  } else {
    uid = null
  }

  if (uid != null && uid != "null" && !orderInProgress) {
    retrieveOrders(uid).then(snapshot => {
      let order = snapshot.val()[Object.keys(snapshot.val())]
      if (order.status === "in-progress") {
        let items = order
        delete items.dateUpdated
        delete items.orderNumber
        delete items.status
        delete items.uid
        setOrderInProgress(true)
        setActiveOrder(items)
      } else {
        // no order in progress
      }
    })
  }

  let products = allProducts.filter(product => {
    return product.description.toLowerCase().indexOf(term.toLowerCase()) >= 0
  })

  return (
    <Layout>
      <SEO
        title="Tick Tock Inc."
        description="Importer and Distributor of General Merchandise in the Greater New York City Area."
      />
      <div className="tabs is-toggle is-centered is-toggle-rounded">
        <ul>
          <li>
            <Link to="/categories/all">All</Link>
          </li>
          <li>
            <Link to="/categories/aluminum">Aluminum</Link>
          </li>
          <li>
            <Link to="/categories/cleaning">Cleaning</Link>
          </li>
          <li>
            <Link to="/categories/foam">Foam</Link>
          </li>
          <li>
            <Link to="/categories/health-and-beauty">Health & Beauty</Link>
          </li>
          <li>
            <Link to="/categories/aid">Medicine & Aid</Link>
          </li>
          <li>
            <Link to="/categories/mops-and-brooms">Mops & Brooms</Link>
          </li>
          <li>
            <Link to="/categories/plastic">Plastic</Link>
          </li>
          <li>
            <Link to="/categories/paper">Paper</Link>
          </li>
          <li>
            <Link to="/categories/stationary">Stationary</Link>
          </li>
          <li>
            <Link to="/categories/misc">Miscellaneous</Link>
          </li>
        </ul>
      </div>

      <Search />

      <div className="index-container">
        <h1 className="is-size-3 has-text-centered">
          Search results for <b>{term}</b>
        </h1>

        <div className="flex">
          {products.map(product => (
            <ProductCell
              product={product}
              uid={uid}
              orderInProgress={orderInProgress}
              activeOrder={activeOrder}
              key={product.itemCode}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SearchQuery {
    allGoogleSpreadsheetSheet1(
      filter: { deleted: { ne: "yes" }, stock: { eq: "yes" } }
    ) {
      nodes {
        category
        subcategory
        itemCode
        packing
        price
        description
        stock
      }
    }
  }
`

export default Home
